import services from ".";
import { API } from "../constants/api.constant";

export const getData = async () => {
  try {
    const rs = await services.get(API.DASHBOARD);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const dashboardServices = {
  getData,
};

export default dashboardServices;
