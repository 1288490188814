function DxfFileIcon(props: any) {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.75 0C4.95937 0 4.3125 0.646875 4.3125 1.4375V21.5625C4.3125 22.3531 4.95937 23 5.75 23H20.125C20.9156 23 21.5625 22.3531 21.5625 21.5625V5.75L15.8125 0H5.75Z"
        fill="#E2E5E7"
      />
      <path
        d="M17.25 5.75H21.5625L15.8125 0V4.3125C15.8125 5.10313 16.4594 5.75 17.25 5.75Z"
        fill="#B0B7BD"
      />
      <path d="M21.5625 10.0625L17.25 5.75H21.5625V10.0625Z" fill="#CAD1D8" />
      <path
        d="M18.6875 18.6875C18.6875 19.0828 18.3641 19.4062 17.9688 19.4062H2.15625C1.76094 19.4062 1.4375 19.0828 1.4375 18.6875V11.5C1.4375 11.1047 1.76094 10.7812 2.15625 10.7812H17.9688C18.3641 10.7812 18.6875 11.1047 18.6875 11.5V18.6875Z"
        fill="#576D7E"
      />
      <path
        d="M4.39808 16.9902C4.2077 16.9902 4 16.8864 4 16.6332V13.3577C4 13.1507 4.2077 13 4.39808 13H5.71854C8.35368 13 8.29598 16.9902 5.77046 16.9902H4.39808ZM4.76155 13.7039V16.2871H5.71854C7.27553 16.2871 7.34477 13.7039 5.71854 13.7039H4.76155Z"
        fill="white"
      />
      <path
        d="M13.6343 13.7507V14.6673H15.1048C15.3125 14.6673 15.5202 14.875 15.5202 15.0762C15.5202 15.2666 15.3125 15.4224 15.1048 15.4224H13.6343V16.6332C13.6343 16.8352 13.4908 16.9902 13.2889 16.9902C13.0351 16.9902 12.88 16.8352 12.88 16.6332V13.3577C12.88 13.1507 13.0358 13 13.2889 13H15.3132C15.5671 13 15.7171 13.1507 15.7171 13.3577C15.7171 13.5423 15.5671 13.75 15.3132 13.75H13.6343V13.7507Z"
        fill="white"
      />
      <path
        d="M10.0718 14.4035L11.0756 13.1465C11.3749 12.7549 11.9576 13.2561 11.6295 13.6312C11.284 14.0408 10.9206 14.485 10.5737 14.9393L11.7506 16.404C12.0672 16.8367 11.4333 17.2521 11.1218 16.8252L10.061 15.4644L9.01743 16.854C8.72319 17.2745 8.07126 16.8021 8.40083 16.4098L9.55974 14.9393C9.19627 14.4843 8.84435 14.0408 8.49314 13.6312C8.12967 13.1985 8.80468 12.7549 9.06935 13.1588L10.0718 14.4035Z"
        fill="white"
      />
      <path
        d="M17.9688 19.4062H4.3125V20.125H17.9688C18.3641 20.125 18.6875 19.8016 18.6875 19.4062V18.6875C18.6875 19.0828 18.3641 19.4062 17.9688 19.4062Z"
        fill="#CAD1D8"
      />
    </svg>
  );
}

export default DxfFileIcon;
