import LoadingIcon from "../SVGs/LoadingIcon";
import styles from "./loadingComponent.module.scss";
import { themes } from "../../constants/theme.constant";

interface DefaultProps {
    loading: boolean
}

const LoadingComponent = (props: DefaultProps) => {
    const { loading } = props
    const color = themes[process.env.REACT_APP_BRAND || "dev"].colorPrimary;

    return (
        <div
            className={`${styles.cloading} ${loading && styles.show
                }`}
        >
            <LoadingIcon color={color} />
        </div>
    );
}

export default LoadingComponent