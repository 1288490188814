import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./slides/auth.slide";
import loadingReducer from "./slides/loading.slide";
import materialReducer from "./slides/material.slide";
import projectReducer from "./slides/project.slide";
import userReducer from "./slides/user.slide";
import configReducer from "./slides/config.slide";
import localeReducer from "./slides/locale.slide";
import unreadReducer from "./slides/unread.slide";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  // counter: counterReducer,
  loading: loadingReducer,
  auth: authReducer,
  material: materialReducer,
  project: projectReducer,
  unread: unreadReducer,
  user: userReducer,
  config: configReducer,
  locale: localeReducer
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.REACT_APP_ENV !== "production",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
