import CompleledIcon from "../SVGs/CompleledIcon";
import RobotIcon from "../SVGs/RobotIcon";
import "./cadFileItem.module.scss";
import LoadingCircleIcon from "../SVGs/LoadingCircleIcon";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { CloseCircleFilled } from "@ant-design/icons";
import { HTMLAttributes } from "react";

interface DefaultProps extends HTMLAttributes<any> {
  loading?: boolean;
  completed?: boolean;
  message?: any;
  error?: boolean;
}

function RobotCalc(props: DefaultProps) {
  const { loading, completed, error, message, ...viewProps } = props;
  const { t } = useTranslation();
  return (
    <Tooltip placement="top" title={message || t("part.robot.message")} trigger={"hover"}>
      <div className="b-robot">
        <RobotIcon width="1.4rem" height="1.4rem" {...viewProps} />
        <div className="b-icon">
          <LoadingCircleIcon
            width="1rem"
            height="1rem"
            style={loading ? { opacity: 1 } : { opacity: 0 }}
          />
          {!error && (
            <CompleledIcon
              width="1rem"
              height="1rem"
              className={completed ? "check" : undefined}
              style={completed ? { opacity: 1 } : { opacity: 0 }}
            />
          )}
          {!!error && <CloseCircleFilled style={{ fontSize: "1rem", color: "var(--bs-danger)" }} />}
        </div>
      </div>
    </Tooltip>
  );
}

export default RobotCalc;
