import services from ".";
import { API } from "../constants/api.constant";
import { SurfaceTreatment } from "../models/SurfaceTreatment";
import { TableFilter } from "../models/Table";

export const create = async (data: SurfaceTreatment) => {
  try {
    const rs = await services.post(API.SURFACE_TREATMENTS, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const list = async (params: TableFilter) => {
  try {
    const rs = await services.get(API.SURFACE_TREATMENTS, { params });
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const detail = async (id: number) => {
  try {
    const rs = await services.get(
      API.SURFACE_TREATMENTS_BY_ID.replace(":id", id.toString())
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const update = async (data: SurfaceTreatment) => {
  try {
    if (data.id) {
      const rs = await services.put(API.SURFACE_TREATMENTS, data);
      return rs.data;
    }
  } catch (error) {
    throw error;
  }
};

const surfaceTreatmentServices = {
  create,
  detail,
  list,
  update,
};

export default surfaceTreatmentServices;
