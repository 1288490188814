import { SelectProps, AutoComplete, Input, Form } from "antd";
import { useEffect, useState } from "react";
import { User } from "../../models/User";
import userServices from "../../services/user.service";

interface DefaultProps {
  onChange?: any;
}

const buildResults = (data: User[]) =>
  data.map((_, idx) => {
    return {
      value: _.email,
      label: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>{_.email}</span>
          <span>
            {_.firstName} {_.lastName}
          </span>
        </div>
      ),
    };
  });

function SelectCustomer(props: DefaultProps) {
  const { onChange } = props;
  const [options, setOptions] = useState<SelectProps<object>["options"]>([]);
  const [userResults, setUserResults] = useState<User[]>([]);
  const [userSelected, setUserSelected] = useState<string>();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (userSelected) {
      const u = userResults.find((u) => u.email === userSelected);
      if (u) {
        setUser(u);
        if (onChange) onChange(u);
      } else {
        if (onChange) onChange(userSelected);
      }
    } else {
      if (onChange) onChange(userSelected);
    }
  }, [userSelected]);

  const findUsers = async (email: string) => {
    try {
      const rs = await userServices.findUser(email);
      setUserResults(rs.data);
      setOptions(buildResults(rs.data));
      if (rs.data.length === 0) {
        setUser(undefined);
        setUserSelected(email);
      } else {
        const uMatch = rs.data.find((u: any) => u.email === email.trim());
        if(uMatch) {
          console.log(uMatch);
          
          if (onChange) onChange(uMatch);
        }
      }
    } catch (error) {}
  };

  const handleSearch = (value: string) => {
    findUsers(value);
  };

  const onSelect = (value: string) => {
    setUserSelected(value);
  };
  return (
    <Form.Item name="email" rules={[{required: true, type: "email"}]}>
      <AutoComplete
        dropdownMatchSelectWidth={252}
        style={{ width: "20vw" }}
        options={options}
        onSelect={onSelect}
        onSearch={handleSearch}
      >
        <Input size="large" placeholder="Customer email" type="email" />
      </AutoComplete>
    </Form.Item>
  );
}

export default SelectCustomer;
