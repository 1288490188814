import { createSlice } from "@reduxjs/toolkit";

export const loadingSlice = createSlice({
  name: "loading",
  initialState: {
    show: false
  },
  reducers: {
    show: (state, action) => {
      state.show = action.payload
    }
  },
});


export const loadingActions = loadingSlice.actions
export const loadingSelector = (state: any) => state.loading;
export default loadingSlice.reducer;
