import {
  CheckOutlined,
  EditOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Switch,
  Table,
  Tag,
  notification,
} from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import CurrencyFormat from "app/components/Format/CurrencyFormat";
import NumberFormat from "app/components/Format/NumberFormat";
import LocalizationFormItem from "app/components/Locale/LocalizationFormItem";
import LocalizationName from "app/components/Locale/LocalizationName";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SurfaceTreatment } from "../../models/SurfaceTreatment";
import { TableData, TableFilter } from "../../models/Table";
import surfaceTreatmentServices from "../../services/surfaceTreatment.service";

interface TableParams extends SorterResult<SurfaceTreatment> {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const SurfaceTreatmentPage: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const [data, setData] = useState<SurfaceTreatment[]>([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [surfaceTreatment, setSurfaceTreatment] = useState<SurfaceTreatment>();
  const [formMachine] = Form.useForm();

  const getData = async () => {
    setLoading(true);
    try {
      const tableFilters: TableFilter = {
        sortDir: tableParams.order != "descend" ? "ASC" : "DESC",
        sortProperty: tableParams.field?.toString(),
        page: (tableParams.pagination?.current || 1) - 1,
        size: tableParams.pagination?.pageSize,
      };
      const rs: TableData = await surfaceTreatmentServices.list(tableFilters);
      setData(rs.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: rs.totalItems,
        },
      });
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [JSON.stringify(tableParams)]);

  useEffect(() => {
    if (surfaceTreatment) {
      formMachine.setFieldsValue(surfaceTreatment);
    } else {
      formMachine.resetFields();
    }
  }, [surfaceTreatment]);

  const handleTableChange: any = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<SurfaceTreatment>
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const getSurfaceTreatment = async (id: number) => {
    try {
      const rs = await surfaceTreatmentServices.detail(id);
      setSurfaceTreatment(rs);
    } catch (error) {}
  };

  const showModal = (id?: number) => {
    setIsModalOpen(true);
    if (id) {
      getSurfaceTreatment(id);
    }
  };

  const onSurfaceTreatmentSubmitted = async (values: SurfaceTreatment) => {
    try {
      if (values.id) {
        await surfaceTreatmentServices.update(values);
        let index = data?.findIndex((m) => m.id == values.id);
        data[index] = { ...data[index], ...values };
        setData([...data]);
      } else {
        const rs = await surfaceTreatmentServices.create(values);
        const newData = [rs, ...data];
        setData(newData);
      }
      handleCancel();
      api.success({
        message: `Update success!`,
        description: <>Surface treatment - <LocalizationName localizations={values.localizations}/></>,
        placement: "topRight",
      });
    } catch (error) {
      api.error({
        message: `Update failed!`,
        description: t("error.default"),
        placement: "topRight",
      });
    }
  };

  const handleCancel = () => {
    setSurfaceTreatment(undefined);
    setIsModalOpen(false);
  };

  const columns: ColumnsType<SurfaceTreatment> = [
    {
      title: "Id",
      dataIndex: "id",
      width: "50px",
      sorter: true,
    },
    {
      title: "ABBR",
      dataIndex: "abbr",
      sorter: true,
    },
    {
      title: t("surfaceTreatment.method"),
      dataIndex: "method",
      sorter: true,
      render: (data, record) => (
        <LocalizationName
          localizations={record.localizations}
          valueDefault={data}
        />
      ),
    },
    {
      title: t("surfaceTreatment.pricePerArea"),
      dataIndex: "pricePerArea",
      sorter: true,
      render: (data) => (
        <>
          <NumberFormat value={data} /> €/dm<sup>2</sup>
        </>
      ),
    },
    {
      title: t("surfaceTreatment.pricePerKilo"),
      dataIndex: "pricePerKilo",
      sorter: true,
      render: (data) => (
        <>
          <NumberFormat value={data} /> €/kg
        </>
      ),
    },
    {
      title: t("surfaceTreatment.setupCost"),
      dataIndex: "setupCost",
      sorter: true,
      render: (data) => <CurrencyFormat value={data} />,
    },
    {
      title: t("surfaceTreatment.minOrder"),
      dataIndex: "minOrder",
      sorter: true,
      render: (data) => <CurrencyFormat value={data} />,
    },
    {
      title: t("surfaceTreatment.deliveryWeek"),
      dataIndex: "deliveryWeek",
      sorter: true,
      render: (data) => `${data} ${t("weeks")}`,
    },
    // {
    //   title: "Preis pro kg",
    //   dataIndex: "pricePerKilo",
    //   sorter: true,
    //   render: (data) => `${data} €/kg`,
    // },
    {
      title: t("material.table.group"),
      dataIndex: "materialGroups",
      width: 300,
      render: (data) => (
        <>
          {data?.map((item: any) => (
            <Tag key={`group-${item.id}`} className="mb-1">
              <LocalizationName
                localizations={item?.localizations}
                valueDefault={item.name}
              />
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: t("status"),
      dataIndex: "active",
      render: (data) =>
        data ? (
          <Tag icon={<CheckOutlined />} color="success">
            {t("active")}
          </Tag>
        ) : (
          <Tag icon={<MinusCircleOutlined />} color="default">
            {t("inactive")}
          </Tag>
        ),
    },
    {
      title: t("action"),
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_: any, record: SurfaceTreatment) => (
        <Button
          type="text"
          icon={<EditOutlined />}
          onClick={showModal.bind(null, record.id)}
        ></Button>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <h3>{t("menu.surfaceTreatments")}</h3>
      <Table
        columns={columns}
        showSorterTooltip={false}
        rowKey={(record) => `surface-${record.id}`}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      <Modal
        title={t("menu.surfaceTreatments")}
        open={isModalOpen}
        onOk={formMachine.submit}
        onCancel={handleCancel}
      >
        <Form
          form={formMachine}
          layout="vertical"
          name="basic"
          autoComplete="off"
          onFinish={onSurfaceTreatmentSubmitted}
          initialValues={{
            active: true,
          }}
        >
          {/* <Alert
            message={
              <>
                {t("surfaceTreatment.note")}{" "}
                <a href="mailto:support@gocad.de">support@gocad.de</a>
              </>
            }
            type="warning"
          /> */}
          <div className="row mt-3">
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="materialGroups" hidden>
              <Input />
            </Form.Item>
            <LocalizationFormItem form={formMachine} data={surfaceTreatment} />
            <div className="col col-12 col-md-6">
              <Form.Item label="ABBR" name="abbr">
                <Input />
              </Form.Item>
            </div>
            {/* <div className="col col-12 col-md-6">
              <Form.Item label={t("surfaceTreatment.method")} name="method">
                <Input />
              </Form.Item>
            </div> */}
            <div className="col col-12 col-md-6">
              <Form.Item
                label={t("surfaceTreatment.pricePerArea")}
                name="pricePerArea"
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  addonAfter={
                    <>
                      €/dm<sup>2</sup>
                    </>
                  }
                />
              </Form.Item>
            </div>
            <div className="col col-12 col-md-6">
              <Form.Item
                label={t("surfaceTreatment.pricePerKilo")}
                name="pricePerKilo"
              >
                <InputNumber addonAfter="€/kg" width={"100%"} min={0} />
              </Form.Item>
            </div>
            <div className="col col-12 col-md-6">
              <Form.Item
                label={t("surfaceTreatment.setupCost")}
                name="setupCost"
              >
                <InputNumber addonAfter="€" width={"100%"} min={0} />
              </Form.Item>
            </div>
            <div className="col col-12 col-md-6">
              <Form.Item label={t("surfaceTreatment.minOrder")} name="minOrder">
                <InputNumber addonAfter="€" width={"100%"} min={0} />
              </Form.Item>
            </div>
            <div className="col col-12 col-md-6">
              <Form.Item
                label={t("surfaceTreatment.deliveryWeek")}
                name="deliveryWeek"
                rules={[{ required: true }]}
              >
                <InputNumber addonAfter={t("week")} width={"100%"} min={0} />
              </Form.Item>
            </div>
            <div className="col col-12 col-md-6">
              <Form.Item
                label={t("surfaceTreatment.transportationCost")}
                name="transportationCost"
                rules={[{ required: true }]}
              >
                <InputNumber width={"100%"} min={0} />
              </Form.Item>
            </div>
            <div className="col col-12 col-md-6">
              <Form.Item label="Status" name="active" valuePropName="checked">
                <Switch
                  checkedChildren={t("active")}
                  unCheckedChildren={t("inactive")}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default SurfaceTreatmentPage;
