/* eslint-disable react/style-prop-object */
const CompleledIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152 152" {...props}>
    <g fill="none" stroke="#249F45" strokeWidth="2">
      <circle
        cx="77"
        cy="77"
        r="72"
        style={{
          strokeDasharray: "480px,480px",
          strokeDashoffset: 960,
        }}
      ></circle>
      <circle
        id="colored"
        fill="#249F45"
        cx="77"
        cy="77"
        r="72"
        style={{
          strokeDasharray: "480px,480px",
          strokeDashoffset: 960,
        }}
      ></circle>
      <polyline
        className="st0"
        stroke="#fff"
        strokeWidth="10"
        points="43.5,77.8 63.7,97.9 112.2,49.4 "
        style={{
          strokeDasharray: "100px,100px",
          strokeDashoffset: 200,
        }}
      />
    </g>
  </svg>
);
export default CompleledIcon;
