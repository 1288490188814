import { useState } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import LoginForm from ".";
import RegisterForm from "../Register/RegisterForm";

function LoginRegister() {
  const { t } = useTranslation();
  const [active, setActive] = useState(0);

  const onTabChange = (value: number) => {
    setActive(value);
  };
  return (
    <div className="login-register">
      <div className="tabs">
        <div className={`l-active active-${active}`}></div>
        <Button
          rootClassName={`${active === 0 && "active"}`}
          type="text"
          className="col col-6"
          onClick={onTabChange.bind(null, 0)}
        >
          {t("login")}
        </Button>
        <Button
          rootClassName={`${active === 1 && "active"}`}
          type="text"
          className="col col-6"
          onClick={onTabChange.bind(null, 1)}
        >
          {t("register")}
        </Button>
      </div>
      <div className="mt-5">
        {active === 0 && <LoginForm />}
        {active === 1 && <RegisterForm />}
      </div>
    </div>
  );
}

export default LoginRegister;
