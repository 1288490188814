import AuthDefault from "app/components/Template/AuthDefault";
import AuthEnsinger from "app/components/Template/AuthEnsinger";
import AuthLST247 from "app/components/Template/AuthLST247";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export function AuthPage() {
  switch (process.env.REACT_APP_BRAND) {
    case "ensinger":
      return <AuthEnsinger />;
    case "lst247":
      return <AuthLST247 />;
    default:
      return <AuthDefault />;
  }
}
