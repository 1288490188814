import { ReactNode } from 'react';
import { useSpring, animated } from 'react-spring';

interface DefaultProps {
    children: ReactNode
    duration?: number | 2000
}

const FadeIn = (props: DefaultProps) => {
    const { children, duration } = props
    const fadeAnimation = useSpring({
        from: { opacity: 0 }, // Initial opacity is 0 (fully transparent)
        to: { opacity: 1 },   // Final opacity is 1 (fully opaque)
        config: { duration } // Duration of the fade-in animation (in milliseconds)
    });

    return <animated.div style={fadeAnimation}>{children}</animated.div>;
};

export default FadeIn;
