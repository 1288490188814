import * as React from "react";
import { SVGProps } from "react";

const MillingSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={50}
    height={50}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M47.369 7.697V1.959a1.64 1.64 0 0 0-1.64-1.64H21.14A1.64 1.64 0 0 0 19.5 1.96v5.738c0 .905.734 1.639 1.64 1.639h2.458v4.098c0 .32-.328-.336 5.615 9.836.21.356.547.62.943.738v14.017a.82.82 0 0 0 .369.68c2.672 1.779 2.573 1.779 2.91 1.779.335 0 .22 0 2.91-1.78a.82.82 0 0 0 .368-.68V24.017a1.64 1.64 0 0 0 .943-.737c5.934-10.172 5.614-9.525 5.614-9.845V9.336h2.46a1.64 1.64 0 0 0 1.639-1.64ZM35.074 29.385l-3.279 2.189V29.45l3.279-2.189v2.123Zm-3.279 4.164 3.279-2.188v2.123l-3.279 2.188V33.55Zm3.279-8.262-3.279 2.188V24.09h3.279v1.197Zm-1.64 13.393-1.639-1.065 3.279-2.156v2.123l-1.64 1.098Zm8.197-26.065h-9.016a.82.82 0 0 0 0 1.64h8.41l-4.78 8.196h-5.622l-4.779-8.197h2.672a.82.82 0 0 0 0-1.64h-3.278V9.337H41.63v3.279Zm4.099-4.918H21.14V1.959h1.639v1.64a.82.82 0 0 0 1.639 0v-1.64h1.64v1.64a.82.82 0 1 0 1.639 0v-1.64h1.64v1.64a.82.82 0 1 0 1.638 0v-1.64h1.64v1.64a.82.82 0 1 0 1.64 0v-1.64h1.638v1.64a.82.82 0 0 0 1.64 0v-1.64h1.64v1.64a.82.82 0 0 0 1.639 0v-1.64h1.639v1.64a.82.82 0 0 0 1.64 0v-1.64h1.639v5.738Z"
      fill="#000"
    />
    <path
      d="M49.008 37.205h-6.967a2.459 2.459 0 0 0-2.205 1.467c-1 2.099-6.73 4.27-14.598 4.27-7.869 0-13.607-2.172-14.598-4.27a2.459 2.459 0 0 0-2.205-1.467H1.467a.82.82 0 0 0-.82.82V48.68c0 .453.368.82.82.82h47.541a.82.82 0 0 0 .82-.82V38.025a.82.82 0 0 0-.82-.82Zm-.82 10.656H2.288v-9.017h6.148a.82.82 0 0 1 .72.525c1.46 3.074 8.075 5.213 16.083 5.213s14.623-2.14 16.082-5.213a.82.82 0 0 1 .721-.525h6.148v9.017Z"
      fill="#000"
    />
    <path
      d="M27.115 37.787a.823.823 0 1 0 1.164-1.164l-6.558-6.557a.823.823 0 1 0-1.163 1.164l6.557 6.557ZM38.59 37.787a.82.82 0 0 0 1.164 0l6.558-6.557a.823.823 0 0 0-1.164-1.165l-6.558 6.558a.82.82 0 0 0 0 1.164ZM18.68 29.008a.82.82 0 1 0 0-1.64.82.82 0 0 0 0 1.64ZM48.189 29.008a.82.82 0 1 0 0-1.639.82.82 0 0 0 0 1.64ZM27.697 32.287a.82.82 0 0 0 .64-1.328l-3.28-4.098a.82.82 0 1 0-1.278 1.016l3.279 4.098a.82.82 0 0 0 .639.312ZM39.172 32.287a.82.82 0 0 0 .64-.312l3.278-4.098a.82.82 0 0 0-.123-1.147.82.82 0 0 0-1.155.13l-3.28 4.099a.82.82 0 0 0 .64 1.328ZM13.295 36.057a2.46 2.46 0 0 0 3.23-1.311 8.369 8.369 0 0 0-3.722-10.869 3.492 3.492 0 0 1-1.59-4.64A8.36 8.36 0 0 0 7.492 8.386a3.5 3.5 0 0 1-1.59-4.64 2.459 2.459 0 1 0-4.517-1.934 8.36 8.36 0 0 0 3.713 10.87 3.5 3.5 0 0 1 1.59 4.647 8.36 8.36 0 0 0 3.722 10.86A3.5 3.5 0 0 1 12 32.837a2.459 2.459 0 0 0 1.295 3.221Zm-2.09-9.295a6.779 6.779 0 0 1-3.008-8.795 5.115 5.115 0 0 0-2.295-6.721 6.762 6.762 0 0 1-3.017-8.787.82.82 0 1 1 1.509.64 5.123 5.123 0 0 0 2.303 6.72 6.762 6.762 0 0 1 3.008 8.788 5.115 5.115 0 0 0 2.303 6.72 6.779 6.779 0 0 1 3.009 8.796.82.82 0 1 1-1.509-.648 5.107 5.107 0 0 0-2.303-6.713ZM21.787 14.984a2.461 2.461 0 0 0-4.574 1.82c.82 2.13.05 2.819.05 2.819a2.46 2.46 0 0 0 2.778 4.057c.172-.114 4.099-2.811 1.746-8.696Zm-2.656 7.327a.82.82 0 1 1-.885-1.377c.303-.23 1.754-1.59.492-4.737a.82.82 0 1 1 1.524-.607c1.861 4.656-1 6.64-1.13 6.721Z"
      fill="#000"
    />
    <path
      d="M5.566 28.738a2.459 2.459 0 0 0-4.615-.91c-.236.424-.342.91-.303 1.393 0 .197.492 4.918 6.787 5.738a2.472 2.472 0 1 0 .59-4.91c-2.254-.279-2.46-1.311-2.46-1.311Zm3.008 3.844a.82.82 0 0 1-.918.721c-4.992-.606-5.369-4.073-5.369-4.23a.82.82 0 0 1 .746-.884.82.82 0 0 1 .893.77c.058.385.55 2.303 3.918 2.713a.82.82 0 0 1 .714.91h.016ZM5.566 44.582h-.82a.82.82 0 1 0 0 1.64h.82a.82.82 0 0 0 0-1.64ZM14.582 44.582H8.844a.82.82 0 1 0 0 1.64h5.738a.82.82 0 0 0 0-1.64Z"
      fill="#000"
    />
  </svg>
);

export default MillingSVG;
