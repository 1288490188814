const RobotIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 1024 1024",
    }}
    viewBox="0 0 1024 1024"
    {...props}
  >
    <path d="M630.08 247.44v37.84h50.88v-37.84c19.98-9.53 33.81-29.85 33.81-53.45 0-32.73-26.53-59.25-59.25-59.25s-59.25 26.53-59.25 59.25c0 23.61 13.84 43.93 33.81 53.45zM381.53 285.29v-37.84c19.98-9.53 33.81-29.85 33.81-53.45 0-32.73-26.53-59.25-59.25-59.25s-59.25 26.53-59.25 59.25c0 23.61 13.84 43.93 33.81 53.45v37.84h50.88zM738.78 303.07H283.36c-90.46 0-163.79 73.33-163.79 163.79v258.62c0 90.46 73.33 163.79 163.79 163.79h455.42c90.46 0 163.79-73.33 163.79-163.79V466.86c0-90.46-73.34-163.79-163.79-163.79zm-119.53 450.6c-12.26 48.38-55.99 84.19-108.18 84.19-52.19 0-95.92-35.81-108.18-84.19-2.56-10.12 5.01-20.01 15.45-20.01H603.8c10.44.01 18.01 9.89 15.45 20.01zm230.33-160.48c0 47.06-38.15 85.21-85.21 85.21h-506.6c-47.06 0-85.21-38.15-85.21-85.21v-116.2c0-47.06 38.15-85.21 85.21-85.21h506.6c47.06 0 85.21 38.15 85.21 85.21v116.2z" />
    <path d="M328.69 494.57c-25.07 0-45.39 20.32-45.39 45.39s20.32 45.38 45.39 45.38c25.06 0 45.38-20.32 45.38-45.38s-20.32-45.39-45.38-45.39z" />
    <circle cx={693.45} cy={539.96} r={45.38} />
    <path d="M1024 581.08c0-52.19-35.81-95.92-84.18-108.18-10.12-2.56-20.01 5-20.01 15.44v185.47c0 10.44 9.89 18.01 20.01 15.45C988.19 677 1024 633.27 1024 581.08zM84.19 472.9C35.81 485.15 0 528.89 0 581.08 0 633.27 35.81 677 84.19 689.26c10.12 2.56 20.01-5.01 20.01-15.45V488.34c-.01-10.44-9.89-18.01-20.01-15.44z" />
  </svg>
)
export default RobotIcon