/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { authActions, authSelector } from "../../redux/slides/auth.slide";
import { projectSelector } from "../../redux/slides/project.slide";
import { setAuthorization } from "../../services";
import style from "./secret.module.scss";

export function SecretPage() {
  const { id, accessCode } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(authSelector);
  const { data } = useSelector(projectSelector);

  const onLogout = () => {
    setAuthorization(undefined);
    dispatch(authActions.clear());
    navigate("/");
  };

  useEffect(() => {
    if (
      !!id &&
      data &&
      auth.user?.sub !== data?.offerUser?.email
    ) {
      onLogout();
    }
  }, [data]);
  return (
    <div className={style.secret}>
      <div className="d-flex flex-column align-items-center mt-3">
        <div className="col col-10">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
