import { Button, Form, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/slides/auth.slide";
import { useState, HTMLAttributes } from "react";
import { useNavigate } from "react-router-dom";
import ListProjects from "./ListProjects";
interface DefaultProps extends HTMLAttributes<any> {
  //   project: Project;
}
function SearchProjects(props: DefaultProps) {
  //   const { project } = props;
  const { t } = useTranslation();
  const [isOpenSearchProject, setIsOpenSearchProject] = useState(false);

  return (
    <>
      <Button
        className="b-search"
        icon={<SearchOutlined />}
        onClick={setIsOpenSearchProject.bind(null, true)}
        block
      >{t("project.search")}</Button>
      <Modal
        title={t("project.search")}
        open={isOpenSearchProject}
        onCancel={setIsOpenSearchProject.bind(null, false)}
        footer={null}
        width="80vw"
        destroyOnClose
      >
        <ListProjects statuses={[]} isSearchMode />
      </Modal>
    </>
  );
}
export default SearchProjects;
