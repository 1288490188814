export enum ShapeConfig {
    MAX_WIDTH = 400,
    HALF_WIDTH = 200,
    GRID_WIDTH = 500,

}

export enum ShapeConfigMobile {
    MAX_WIDTH = 200,
    HALF_WIDTH = 100,
    GRID_WIDTH = 300,
}

export enum GridConfig {
    BREAK_POINT = 1000,
    DESKTOP_WIDTH = 500,
    MOBILE_WIDTH = 300,
}

export const LAYOUT_BREAK_POINT = 1000

export enum ShapeTypes {
    ROUND = 'ROUND',
    RECTANGLE = 'RECTANGLE',
    OVAL = 'OVAL',
    ELONGATED = 'ELONGATED',
    RECTANGLE_PANEL = 'RECTANGLE_PANEL',
    RECTANGLE_ROUNDED_PANEL = 'RECTANGLE_ROUNDED_PANEL',
    RECTANGLE_TOP_ROUNDED_PANEL = 'RECTANGLE_TOP_ROUNDED_PANEL',
    CIRCLE_PANEL = 'CIRCLE_PANEL',
    ELLIPSE_PANEL = 'ELLIPSE_PANEL',
    HALF_CIRCLE_PANEL = 'HALF_CIRCLE_PANEL',
    OCTAGON_PANEL = 'OCTAGON_PANEL',
    TRAPEZ_PANEL = 'TRAPEZ_PANEL',
    ISO_TRAPEZ_PANEL = 'ISO_TRAPEZ_PANEL',
}
