import services from ".";
import { API } from "../constants/api.constant";
import { QuickSetting } from "../models/QuickSetting";

export const getData = async () => {
  try {
    const rs = await services.get(
      API.CONFIG
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const configServices = {
  getData
};

export default configServices;
