function LoadingIcon(props: any) {
  const {color = "#ffcb3d"} = props;
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={150}
    preserveAspectRatio="xMidYMid"
    style={{
      margin: "auto",
      background: "transparent",
      display: "block",
      shapeRendering: "auto",
    }}
    viewBox="0 0 100 100"
    {...props}
  >
    <circle cx={84} cy={50} r={10} fill={color}>
      <animate
        attributeName="r"
        begin="0s"
        calcMode="spline"
        dur="0.8333333333333334s"
        keySplines="0 0.5 0.5 1"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="8;0"
      />
      <animate
        attributeName="fill"
        begin="0s"
        calcMode="discrete"
        dur="3.3333333333333335s"
        keyTimes="0;0.25;0.5;0.75;1"
        repeatCount="indefinite"
        values={`${color};${color};${color};${color};${color}`}
      />
    </circle>
    <circle cx={16} cy={50} r={10} fill={color}>
      <animate
        attributeName="r"
        begin="0s"
        calcMode="spline"
        dur="3.3333333333333335s"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        keyTimes="0;0.25;0.5;0.75;1"
        repeatCount="indefinite"
        values="0;0;8;8;8"
      />
      <animate
        attributeName="cx"
        begin="0s"
        calcMode="spline"
        dur="3.3333333333333335s"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        keyTimes="0;0.25;0.5;0.75;1"
        repeatCount="indefinite"
        values="16;16;16;50;84"
      />
    </circle>
    <circle cx={50} cy={50} r={10} fill={color}>
      <animate
        attributeName="r"
        begin="-0.8333333333333334s"
        calcMode="spline"
        dur="3.3333333333333335s"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        keyTimes="0;0.25;0.5;0.75;1"
        repeatCount="indefinite"
        values="0;0;8;8;8"
      />
      <animate
        attributeName="cx"
        begin="-0.8333333333333334s"
        calcMode="spline"
        dur="3.3333333333333335s"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        keyTimes="0;0.25;0.5;0.75;1"
        repeatCount="indefinite"
        values="16;16;16;50;84"
      />
    </circle>
    <circle cx={84} cy={50} r={10} fill={color}>
      <animate
        attributeName="r"
        begin="-1.6666666666666667s"
        calcMode="spline"
        dur="3.3333333333333335s"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        keyTimes="0;0.25;0.5;0.75;1"
        repeatCount="indefinite"
        values="0;0;8;8;8"
      />
      <animate
        attributeName="cx"
        begin="-1.6666666666666667s"
        calcMode="spline"
        dur="3.3333333333333335s"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        keyTimes="0;0.25;0.5;0.75;1"
        repeatCount="indefinite"
        values="16;16;16;50;84"
      />
    </circle>
    <circle cx={16} cy={50} r={10} fill={color}>
      <animate
        attributeName="r"
        begin="-2.5s"
        calcMode="spline"
        dur="3.3333333333333335s"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        keyTimes="0;0.25;0.5;0.75;1"
        repeatCount="indefinite"
        values="0;0;8;8;8"
      />
      <animate
        attributeName="cx"
        begin="-2.5s"
        calcMode="spline"
        dur="3.3333333333333335s"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        keyTimes="0;0.25;0.5;0.75;1"
        repeatCount="indefinite"
        values="16;16;16;50;84"
      />
    </circle>
  </svg>
  );
}

export default LoadingIcon;
