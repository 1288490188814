/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import partServices from "../../services/part.service";
import { decode } from "js-base64";
import $ from "jquery";
import "./processingReport.module.scss";
import { Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
import NumberFormat from "../Format/NumberFormat";
const { Option } = Select;

interface DefaultProps {
  partId?: number;
}

function ProcessingReport(props: DefaultProps) {
  const { t } = useTranslation();
  const { partId } = props;
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState<any>();
  const [data, setData] = useState<any>();
  const [base64Images, setBase64Images] = useState<any>({});
  const [svgDiagrams, setSvgDiagrams] = useState<any>({});
  const [featureKeys, setFeatureKeys] = useState<any>({});

  const getProcessingReport = async () => {
    try {
      const rs = await partServices.getProcessingReport(partId);
      setReports(rs.reports);
      setData(rs.reports[0]);
    } catch (error) {}
    setLoading(false);
  };
  const onHightlight = (key: any) => {
    $(`.text-key`).removeClass("active");
    $(`.part-item`).removeClass("active");
    $(`.part-item.${key}`).addClass("active");
  };
  useEffect(() => {
    getProcessingReport();
  }, []);
  useEffect(() => {
    $(".text-key").on("click", function (e: any) {
      onHightlight($(this).attr("data"));
    });
  }, [featureKeys]);
  useEffect(() => {
    if (data) {
      let b64Images = base64Images;
      let svgDs = svgDiagrams;
      let features: any = {};
      data.views.map((item: any) => {
        const key = item.orientation.toLowerCase();
        const elImage = $(decode(item.svgDiagram));
        b64Images = {
          ...b64Images,
          [key]: elImage.find("image")[0].href.baseVal,
        };
        const fkeys = elImage
          .find("text")
          .toArray()
          .map((e) => {
            $(e).addClass(`text-key svg-${key}-${$(e).text()}`);
            $(e).attr("data", `${key}-${$(e).text()}`);
            $(e).attr("key", `${key}-${$(e).text()}`);
            // $(e).attr("onclick", `svgKeyClick("${key}-${$(e).text()}")`);
            return $(e).text();
          });
        svgDs = {
          ...svgDs,
          [key]: elImage[0].outerHTML,
        };
        features = {
          ...features,
          [key]: fkeys,
        };
        return false;
      });
      setBase64Images(b64Images);
      setSvgDiagrams(svgDs);
      setFeatureKeys(features);
    }
  }, [data]);
  const RenderRows = (key: any) => {
    const keys = featureKeys[key];
    const fts = data?.features?.filter((f: any) => keys?.indexOf(f.label) > -1);
    return fts.map((item: any) => {
      return item.operations.map((o: any) => (
        <tr key={`${o.id}`} className={`part-item ${key}-${item.label}`}>
          <td>{item.label}</td>
          <td>
            {t(`processingReport.object.${item.localizedType}`, {
              defaultValue: item.localizedType,
            })}
          </td>
          <td>
            {t(`processingReport.machining.${o.localizedSubType}`, {
              defaultValue: o.localizedSubType,
            })}
          </td>
          <td>{t(`machines.${o.machine}`)}</td>
          <td>{o.tool}</td>
          <td>
            <NumberFormat value={o.operationTime} />
          </td>
          <td>
            <NumberFormat value={o.toolPath} />
          </td>
          <td>
            <NumberFormat value={o.feedSpeed} />
          </td>
        </tr>
      ));
    });
  };

  const RenderToolRows = () => {
    return data?.tools?.map((item: any) => (
      <tr key={`${item.id}`} className={`tools-item${item.id}`}>
        <td>
          {t(`processingReport.tools.${item.localizedType}`, {
            defaultValue: item.localizedType || "",
          })}
        </td>
        <td>{t(`machines.${item.machine}`)}</td>
        <td
          style={{
            maxWidth: "10vw",
            whiteSpace: "break-spaces",
          }}
        >
          {item.features.join(", ")}
        </td>
        <td>
          <NumberFormat value={item.diameter} />
        </td>
        <td>
          <NumberFormat value={item.operationTime} />
        </td>
      </tr>
    ));
  };

  const onVariantChange = (key: number) => {
    setData(reports[key]);
  };

  if (loading)
    return (
      <div className="box-loading">
        <Spin />
      </div>
    );

  return (
    <>
      <div>
        <div className="col col-12 col-md-5">
          <h5>{t("processingReport.views")}</h5>
          <div className="row">
            <div className="col-3"></div>
            <div className="col-3 box-img mb-2">
              <span>{t("processingReport.top")}</span>
              <a className="process-img" href="#part-top">
                <img id="imgTop" src={base64Images.top} />
              </a>
            </div>
            <div className="col-3"></div>
            <div className="col-3"></div>
            <div className="col-3  box-img">
              <span>{t("processingReport.right")}</span>
              <a className="process-img" href="#part-right">
                <img id="imgRight" src={base64Images.right} />
              </a>
            </div>
            <div className="col-3 box-img">
              <span>{t("processingReport.front")}</span>
              <a className="process-img" href="#part-front">
                <img id="imgFront" src={base64Images.front} />
              </a>
            </div>
            <div className="col-3 box-img">
              <span>{t("processingReport.left")}</span>
              <a className="process-img" href="#part-left">
                <img id="imgLeft" src={base64Images.left} />
              </a>
            </div>
            <div className="col-3 box-img">
              <span>{t("processingReport.back")}</span>
              <a className="process-img" href="#part-back">
                <img id="imgBack" src={base64Images.back} />
              </a>
            </div>
            <div className="col-3"></div>
            <div className="col-3 box-img mt-2">
              <span>{t("processingReport.bottom")}</span>
              <a className="process-img" href="#part-bottom">
                <img id="imgBottom" src={base64Images.bottom} />
              </a>
            </div>
            <div className="col-3"></div>
            <div className="col-3"></div>
          </div>
        </div>
      </div>
      <div>
        <div className="mt-3">
          <h6>{t("processingReport.variants")}</h6>
          <Select
            onChange={onVariantChange}
            style={{ width: "20vw" }}
            defaultValue={0}
          >
            <Option value={0}>{t("processingReport.variant1")}</Option>
            {reports?.length > 1 && (
              <Option value={1}>{t("processingReport.variant2")}</Option>
            )}
          </Select>
        </div>
        {data?.views?.map((item: any) => (
          <div
            className="view-item"
            key={`feature-${item.orientation}`}
            id={`part-${item.orientation.toLowerCase()}`}
          >
            <div className="v-title mb-3 mt-5">
              {t(`processingReport.${item.orientation.toLowerCase()}`)}
            </div>
            <div className="row">
              <div
                className="col col-12 col-md-4 box-diagram"
                // children={svgDiagrams[item.orientation.toLowerCase()]}
                dangerouslySetInnerHTML={{
                  __html: svgDiagrams[item.orientation.toLowerCase()],
                }}
              ></div>
              <div className="col col-12 col-md-8">
                <div className="table-part">
                  <table className="table table-striped table-part">
                    <tbody>
                      <tr>
                        <th>Index</th>
                        <th>{t("processingReport.object")}</th>
                        <th>{t("processingReport.step")}</th>
                        <th>{t("machines")}</th>
                        <th>{t("processingReport.tools.name")}</th>
                        <th>{t("processingReport.processTime")}</th>
                        <th>{t("processingReport.travel")}</th>
                        <th>{t("processingReport.cuttingSpeed")}</th>
                      </tr>
                      {RenderRows(item.orientation.toLowerCase())}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="view-item">
          <div className="v-title mb-3 mt-5">{t("processingReport.tools")}</div>
          <table className="table table-striped table-part">
            <tbody>
              <tr>
                <th>{t("processingReport.tools.name")}</th>
                <th>{t("machines")}</th>
                <th
                  style={{
                    maxWidth: "10vw",
                  }}
                >
                  {t("processingReport.tools.area")}
                </th>
                <th>{t("processingReport.tools.diameter")}</th>
                <th>{t("processingReport.tools.time")}</th>
              </tr>
              {RenderToolRows()}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
export default ProcessingReport;
