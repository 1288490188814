import { DeliveryOption } from "./DeliveryOption";
import { Order } from "./Order";
import { Part } from "./Part";
import { User } from "./User";

export interface Project {
  id?: number;
  name?: string;
  parts?: Part[];
  deliveryOption: DeliveryOption;
  deliveryDate: any;
  linkedProject: any;
  order: Order;
  status: ProjectStatus;
  manual: boolean;
  blocked: boolean;
  adapted: boolean;
  createdBy?: User;
  readBySeller: boolean;
  readByBuyer: boolean;
  totalWeight: number;
  selfCalculation?: boolean;
  offerUser?: User;
  createdAt?: string;
  archived?: boolean;
}

export enum ProjectStatus {
  IN_PROGRESS = "a_IN_PROGRESS",
  CHECKOUTED = "b_CHECKOUTED",
  REQUESTED_OFFER = "c_REQUESTED_OFFER",
  PRICE_ADAPTED = "d_PRICE_ADAPTED",
  SELLER_APPROVED = "e_SELLER_APPROVED",
  BUYER_APPROVED = "f_BUYER_APPROVED",
  SELLER_REJECTED = "g_SELLER_REJECTED",
  BUYER_REJECTED = "h_BUYER_REJECTED"
}

export enum ListViewMode {
  EDIT,
  CHECKOUT,
  READ_ONLY,
  PRICE_ADAPT
}
