import GridLines from 'react-gridlines';
import Ruler from "@scena/react-ruler";
import { Suspense, cloneElement } from 'react';

interface DefaultProps {
    selectedShape: any
    shapeConfig: any
    config?: any
}

const ShapeGridLines = (props: DefaultProps) => {
    const { selectedShape, shapeConfig, config } = props
    const RenderShape = () => {
        if (shapeConfig) {
            return cloneElement(selectedShape.component, { ...shapeConfig, config: config })
        }

        return cloneElement(selectedShape.component)
    }

    let rulerPosition = 0
    if (shapeConfig && shapeConfig.scale != 1) {
        const objectWidth = config.MAX_WIDTH / shapeConfig.scale
        rulerPosition = (objectWidth / 2) - config.HALF_WIDTH
    }

    return (
        <div style={{ width: `${config.GRID_WIDTH}px`, height: `${config.GRID_WIDTH}px` }}>
            <GridLines className="grid-area justify-content-center align-item-center" cellWidth={50} cellWidth2={10} >
                <div className="grid-horizontal-ruler" style={{ position: 'absolute', zIndex: 999, left: '50%' }}>
                    <Ruler type="horizontal" backgroundColor="black" textColor="white" font='15px Segoe UI' height={40} width={config.GRID_WIDTH / 2} scrollPos={rulerPosition} />
                </div>
                <Suspense>
                    {(selectedShape) && <RenderShape />}
                </Suspense>
                <div className="grid-vertical-ruler" style={{ position: 'absolute', top: '50%', height: '50%' }}>
                    <Ruler type="vertical" backgroundColor="black" textColor="white" font='15px Segoe UI' direction="end" width={40} height={config.GRID_WIDTH / 2} scrollPos={rulerPosition} />
                </div>
            </GridLines>
        </div>
    )
}

export default ShapeGridLines