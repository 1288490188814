import { useSelector } from "react-redux";
import { loadingSelector } from "../../redux/slides/loading.slide";
import styles from "./loading.module.scss";
import LoadingIcon from "../SVGs/LoadingIcon";
import { authSelector } from "../../redux/slides/auth.slide";
import { themes } from "../../constants/theme.constant";
const AppLoading = () => {
  const loading = useSelector(loadingSelector);
  const auth = useSelector(authSelector);
  const color = themes[process.env.REACT_APP_BRAND || "dev"].colorPrimary;
  return (
    <div
      className={`${styles.loading} ${auth.isLogined && styles.logined || ""} ${
        loading.show && styles.show || ""
      }`}
    >
      {/* <ReactLoading type={"bubbles"} color="#000" width={'10vw'} height={'10vw'}/> */}
      <LoadingIcon color={color} />
    </div>
  );
};

export default AppLoading;
