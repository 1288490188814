import { useTranslation } from "react-i18next";

function RegisterSuccess() {
  const { t } = useTranslation();
  return (
    <div>
      <h5>{t("register.success.msg1")}</h5>
      <p className="sub-l">{t("register.success.msg2")}</p>
      <p className="sub-l">
      {t("register.success.msg3")}
      </p>
    </div>
  );
}

export default RegisterSuccess;
