import { createSlice } from "@reduxjs/toolkit";

export const unreadSlice = createSlice({
  name: "unread",
  initialState: {
    data: {
      PLACED_ORDERS: 0,
      RECEIVED_ORDERS: 0,
      SELF_DISPATCHED_OFFERS: 0
    },
  },
  reducers: {
    setData: (state, action) => {
      const key: "PLACED_ORDERS" | "RECEIVED_ORDERS" = action.payload.key;
      const data: any = action.payload.data;
      state.data[key] = data;
    },
    clearData: (state) => {
      state.data = {
        PLACED_ORDERS: 0,
        RECEIVED_ORDERS: 0,
        SELF_DISPATCHED_OFFERS: 0
      }
    }
  },
});

export const unreadActions = unreadSlice.actions;
export const unreadSelector = (state: any) => state.unread;
export default unreadSlice.reducer;
