import { Alert } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { routers } from "../../constants/router.constant";
import { loadingActions } from "../../redux/slides/loading.slide";
import userServices from "../../services/user.service";
import { useTranslation } from "react-i18next";

function RegisterVerify() {
  let [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const code: any = searchParams.get("code");
    const verify = async () => {
      dispatch(loadingActions.show(true));
      try {
        await userServices.verify(code);
        setVerified(true);
      } catch (e) {
        setError(e);
      }
      dispatch(loadingActions.show(false));
    };
    verify();
  }, []);

  if (error)
    return (
      <Alert
        message="Error"
        showIcon
        description={error.message}
        type="error"
      />
    );
  if (!verified) return <div></div>;
  return (
    <div>
      <h5>{t("register.verify.title")}</h5>
      <Link to={routers.HOME}>{t("register.verify.btn")}</Link>
    </div>
  );
}

export default RegisterVerify;
