import * as React from "react";
import { SVGProps } from "react";

const SheetMetalSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.8196 14.2237H4.65596C3.03135 14.2237 1.71436 12.9066 1.71436 11.282V4.41816C1.71436 2.79355 3.03135 1.47656 4.65596 1.47656H46.8195C48.4442 1.47656 49.7612 2.79355 49.7612 4.41816V11.282C49.7612 12.9066 48.4442 14.2237 46.8196 14.2237Z"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.59761 9.81124C8.68071 9.81124 9.55874 8.93321 9.55874 7.85011C9.55874 6.76701 8.68071 5.88898 7.59761 5.88898C6.5145 5.88898 5.63647 6.76701 5.63647 7.85011C5.63647 8.93321 6.5145 9.81124 7.59761 9.81124Z"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4421 9.81124C16.5252 9.81124 17.4032 8.93321 17.4032 7.85011C17.4032 6.76701 16.5252 5.88898 15.4421 5.88898C14.359 5.88898 13.481 6.76701 13.481 7.85011C13.481 8.93321 14.359 9.81124 15.4421 9.81124Z"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.6017 18.1459H18.874C17.2494 18.1459 15.9324 16.8289 15.9324 15.2043V14.2238H35.5433V15.2043C35.5433 16.8289 34.2263 18.1459 32.6017 18.1459Z"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.1503 22.068H21.3254C19.7008 22.068 18.3838 20.751 18.3838 19.1264V18.1459H33.092V19.1264C33.0919 20.751 31.7749 22.068 30.1503 22.068Z"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.3059 22.0681V28.9319L25.7378 33.8347L29.1698 28.9319V22.0681H22.3059Z"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.614 35.2634L25.7378 43.1499L4.86177 35.2634C4.1479 34.9937 3.67554 34.3103 3.67554 33.5472C3.67554 32.2644 4.95845 31.3777 6.15845 31.831L25.7378 39.2278L45.3173 31.8311C46.5173 31.3778 47.8002 32.2645 47.8002 33.5473C47.8001 34.3103 47.3278 34.9937 46.614 35.2634Z"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.3447 49.5234V41.1123"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.1309 49.5234V41.1123"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.71436 49.5234H49.7612"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SheetMetalSVG;
